import React, { FC } from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { WixRicosViewer } from '@wix/ricos-viewer';
import { useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { ISettingsColor } from '@wix/yoshi-flow-editor/tpa-settings';

import {
  useInstance,
  useDefaultBIParams,
  useMember,
} from '../../../../../../contexts/widgets';
import { biSettings, plugins } from './config';
import '../../../common/RCE/ricos.global.scss';
import stylesParams from '../../../../stylesParams';

const isRgbValue = (value: string) => value.includes('rgb(');
const rgbToRgba = (value: string, opacity: number) =>
  value.replace('rgb', 'rgba').replace(')', `,${opacity})`);
const getRgbaTextColor = ({ value, opacity }: ISettingsColor) =>
  isRgbValue(value) ? rgbToRgba(value, opacity) : value;

export const Viewer: FC = () => {
  const { isMobile, language } = useEnvironment();
  const { instance } = useInstance();
  const { defaultBIParams } = useDefaultBIParams();
  const { experiments } = useExperiments();
  const { id, about } = useMember();
  const styles = useStyles();
  const textColor = styles.get(stylesParams.sectionDescriptionColor);

  return (
    <WixRicosViewer
      theme={{
        palette: { textColor: getRgbaTextColor(textColor) },
      }}
      content={about}
      plugins={plugins}
      instance={instance}
      wixExperiments={experiments}
      isMobile={isMobile}
      locale={language}
      biSettings={{
        ...biSettings,
        contentId: id ?? undefined,
        defaultParams: defaultBIParams,
      }}
    />
  );
};
