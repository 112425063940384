import { pluginImage } from 'wix-rich-content-plugin-image/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/viewer';
import { pluginDivider } from 'wix-rich-content-plugin-divider/viewer';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/viewer';
import { pluginEmoji } from 'wix-rich-content-plugin-emoji/viewer';
import { pluginLink } from 'wix-rich-content-plugin-link/viewer';

export const biSettings = {
  platform: 'Livesite' as const,
  consumer: 'members-about-ooi',
  usage: 'About (preview)',
};

export const plugins = [
  pluginImage({ disableExpand: true }),
  pluginVideo(),
  pluginDivider(),
  pluginGiphy(),
  pluginEmoji(),
  pluginLink(),
];
